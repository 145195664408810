import { CheckBox } from '@isdd/idsk-ui-kit/checkbox/CheckBox'
import { DateInput, DateTypeEnum } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { Input, MultiSelect, SimpleSelect } from '@isdd/idsk-ui-kit/index'
import { TextArea } from '@isdd/idsk-ui-kit/text-area/TextArea'
import { EnumItem, EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { Attribute, AttributeAttributeTypeEnum, AttributeConstraintRegexAllOf } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { CiLazySelect } from '@isdd/metais-common/components/ci-lazy-select/CiLazySelect'
import { RichTextQuill } from '@isdd/metais-common/components/rich-text-quill/RichTextQuill'
import { ConstraintTypes, EUR, EURO, HTML_TYPE } from '@isdd/metais-common/constants'
import { isConstraintCiType } from '@isdd/metais-common/hooks/useGetCiTypeConstraintsData'
import { formatDateToIso } from '@isdd/metais-common/index'
import { Languages } from '@isdd/metais-common/localization/languages'
import { formatNumberWithSpaces, isFalsyStringValue } from '@isdd/metais-common/utils/utils'
import classnames from 'classnames'
import React from 'react'
import {
    Control,
    Controller,
    FieldError,
    FieldErrorsImpl,
    FieldValues,
    Merge,
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetValue,
    UseFormTrigger,
    UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { sanitizedHtml } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'

import { ArrayAttributeInput } from './ArrayAttributeInput'
import { AttributesConfigTechNames, attClassNameConfig } from './attributeDisplaySettings'
import { createOptions, getDefaultArrayValue, getDefaultValue, getDefaultValueForSimple, onKeyDownOfNumberInput } from './attributeInputHelpers'

import { getSpecialRequiredProperty } from '@/componentHelpers/ci/ciEntityFormRequiredPropertySpecialRulesConfig'
import { getSpecialValueProperty } from '@/componentHelpers/ci/ciEntityFormValueSpecialRulesConfig'
import { HasResetState } from '@/components/create-entity/CreateCiEntityForm'

enum MandatoryType {
    CRITICAL = 'critical',
}

enum DisplayTextArea {
    TEXTAREA = 'textarea',
}

interface IAttributeInput {
    nameSuffix?: string
    namePrefix?: string
    attribute: Attribute
    register: UseFormRegister<FieldValues>
    clearErrors: UseFormClearErrors<FieldValues>
    constraints?: EnumType
    error: FieldError | Merge<FieldError, FieldErrorsImpl> | undefined
    watch?: UseFormWatch<FieldValues>
    hint?: string
    isSubmitted: boolean
    unitsData?: EnumItem
    setValue: UseFormSetValue<FieldValues>
    trigger: UseFormTrigger<{
        [x: string]:
            | string
            | number
            | boolean
            | Date
            | unknown
            | (string | undefined)[]
            | {
                  label?: string | undefined
                  value?: string | undefined
              }[]
            | null
            | undefined
    }>
    defaultValueFromCiItem?: string | boolean | string[] | number
    hasResetState: HasResetState
    disabled?: boolean
    isUpdate?: boolean
    control: Control
    maxLength?: number
    entityName?: string
}

export const AttributeInput: React.FC<IAttributeInput> = ({
    attribute,
    trigger,
    setValue,
    register,
    error,
    constraints,
    hint,
    isSubmitted,
    unitsData,
    defaultValueFromCiItem,
    hasResetState,
    clearErrors,
    watch,
    disabled,
    nameSuffix = '',
    namePrefix = '',
    isUpdate,
    control,
    maxLength,
    entityName,
}) => {
    const { t, i18n } = useTranslation()

    const hasUnits = !!attribute.units && !!unitsData
    const unitsLabel = hasUnits ? ' ' + t('createEntity.units', { units: unitsData.value }) : ''

    const isCorrect = !error && isSubmitted

    const isRequired =
        getSpecialRequiredProperty({
            technicalName: namePrefix + attribute.technicalName + nameSuffix,
            entityName,
            watch,
            clearErrors,
            error,
            nameSuffix,
        }) ??
        (attribute.mandatory?.type === MandatoryType.CRITICAL && !attribute.readOnly)
    const requiredText = ` (${t('createEntity.required')})`
    const requiredLabel = `${isRequired ? requiredText : ''}`

    const isString = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.STRING
    const isTextarea = attribute.displayAs === DisplayTextArea.TEXTAREA
    const isCharacter = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.CHARACTER
    const isInteger = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.INTEGER
    const isDouble = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.DOUBLE
    const isByte = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.BYTE
    const isShort = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.SHORT
    const isLong = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.LONG
    const isFloat = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.FLOAT
    const isDate = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.DATE
    const isDateTime = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.DATETIME
    const isBoolean = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.BOOLEAN
    const isFile = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.IMAGE
    const isUrl = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.URL
    const isPhone = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.PHONE
    const isEmail = attribute.attributeTypeEnum === AttributeAttributeTypeEnum.EMAIL

    const isArray = attribute.array
    const isHTML = attribute.type === HTML_TYPE

    const isEnum = attribute?.constraints && attribute.constraints.length > 0 && attribute?.constraints[0].type === ConstraintTypes.ENUM

    const isCiTypeConstraint =
        attribute?.constraints && attribute.constraints.length > 0 && attribute?.constraints[0].type === ConstraintTypes.CI_TYPE
    const ciType = isConstraintCiType(attribute?.constraints?.[0]) ? attribute?.constraints?.[0].ciType ?? '' : ''

    const isRegex = attribute?.constraints && attribute.constraints.length > 0 && attribute?.constraints[0].type === ConstraintTypes.REGEX
    const hasNumericValue = isInteger || isDouble || isFloat || isByte || isLong || isShort
    const hasStringValue = isString || isCharacter || isPhone || isEmail

    const createDefaultValuesForMulti = (constraintItem: EnumType, values: string[]) => {
        const options = values.map((value) => {
            const foundItem = constraintItem.enumItems?.find((item) => item.code == value)
            return foundItem?.code ?? ''
        })
        return options
    }

    const handleDateTimeChange = (date: Date | null, name: string) => {
        setValue(name, date ? formatDateToIso(date) : null)
    }

    const getStringType = (attributeType?: AttributeAttributeTypeEnum) => {
        switch (attributeType) {
            case AttributeAttributeTypeEnum.EMAIL:
                return 'email'
            case AttributeAttributeTypeEnum.PHONE:
                return 'tel'
            default:
                return 'text'
        }
    }

    const regexConstraints = attribute?.constraints?.[0] as AttributeConstraintRegexAllOf

    const renderContent = () => {
        if (attribute.technicalName == null) return <></>
        switch (true) {
            case isArray && !isEnum && !isCiTypeConstraint: {
                return (
                    <ArrayAttributeInput
                        isSubmitted={isSubmitted}
                        register={register}
                        attribute={attribute}
                        error={error}
                        isCorrect={isCorrect}
                        isTextarea={isTextarea}
                        requiredLabel={requiredLabel}
                        setValue={setValue}
                        trigger={trigger}
                        defaultValue={getDefaultArrayValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate, isRequired)}
                        hasResetState={hasResetState}
                        disabled={disabled}
                        nameSuffix={nameSuffix}
                        namePrefix={namePrefix}
                    />
                )
            }
            case isDate: {
                return (
                    <DateInput
                        setValue={setValue}
                        {...register(namePrefix + attribute.technicalName + nameSuffix)}
                        name={namePrefix + attribute.technicalName + nameSuffix}
                        control={control}
                        type={DateTypeEnum.DATE}
                        correct={isCorrect}
                        info={attribute.description}
                        id={attribute.technicalName}
                        clearErrors={clearErrors}
                        disabled={attribute.readOnly || disabled}
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        error={error?.message?.toString()}
                        hint={hint}
                    />
                )
            }
            case isDateTime: {
                return (
                    <DateInput
                        setValue={setValue}
                        handleDateChange={handleDateTimeChange}
                        {...register(namePrefix + attribute.technicalName + nameSuffix)}
                        name={namePrefix + attribute.technicalName + nameSuffix}
                        control={control}
                        type={DateTypeEnum.DATETIME}
                        correct={isCorrect}
                        info={attribute.description}
                        id={attribute.technicalName}
                        clearErrors={clearErrors}
                        disabled={attribute.readOnly || disabled}
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        error={error?.message?.toString()}
                        hint={hint}
                    />
                )
            }
            case isFile: {
                return (
                    <Input
                        isUpload
                        correct={isCorrect}
                        type="file"
                        info={attribute.description}
                        id={attribute.technicalName}
                        disabled={attribute.readOnly || disabled}
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        error={error?.message?.toString()}
                        {...register(namePrefix + attribute.technicalName + nameSuffix)}
                        hint={defaultValueFromCiItem?.toString()}
                    />
                )
            }
            case isUrl: {
                return (
                    <Input
                        correct={isCorrect}
                        type="url"
                        info={attribute.description}
                        id={attribute.technicalName}
                        disabled={attribute.readOnly || disabled}
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        error={error?.message?.toString()}
                        {...register(namePrefix + attribute.technicalName + nameSuffix)}
                        hint={defaultValueFromCiItem?.toString()}
                    />
                )
            }
            case isBoolean: {
                const defaultValue = getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate)
                return (
                    <div className="govuk-form-group">
                        <Controller
                            name={namePrefix + attribute.technicalName + nameSuffix}
                            control={control}
                            defaultValue={isFalsyStringValue(defaultValue) ? false : !!defaultValue}
                            render={({ field: { onChange, value, name } }) => {
                                return (
                                    <CheckBox
                                        name={name}
                                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}`}
                                        error={error?.message?.toString()}
                                        id={attribute.technicalName ?? ''}
                                        info={attribute.description}
                                        disabled={attribute.readOnly || disabled}
                                        checked={isFalsyStringValue(value) ? false : value}
                                        onChange={(e) => {
                                            onChange(e.target.checked)
                                        }}
                                    />
                                )
                            }}
                        />
                    </div>
                )
            }
            case isCiTypeConstraint && !!ciType: {
                return (
                    <CiLazySelect
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        name={namePrefix + attribute.technicalName + nameSuffix}
                        ciType={ciType}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        error={error?.message?.toString()}
                        disabled={attribute.readOnly || disabled}
                        info={attribute.description}
                        placeholder={t('createEntity.select')}
                        defaultValue={
                            isArray
                                ? attribute.defaultValue || defaultValueFromCiItem
                                    ? getDefaultArrayValue(attribute.defaultValue ?? '', defaultValueFromCiItem)
                                    : undefined
                                : getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem)
                        }
                        metaAttributes={{ state: ['DRAFT'] }}
                        isMulti={isArray}
                    />
                )
            }
            case isEnum: {
                if (constraints) {
                    if (attribute.array) {
                        return (
                            <MultiSelect
                                id={attribute.technicalName ?? ''}
                                name={namePrefix + attribute.technicalName + nameSuffix}
                                label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                                correct={isCorrect}
                                options={createOptions(constraints)}
                                setValue={setValue}
                                error={error?.message?.toString()}
                                clearErrors={clearErrors}
                                disabled={disabled}
                                control={control}
                                placeholder={t('createEntity.select')}
                                defaultValue={createDefaultValuesForMulti(
                                    constraints,
                                    getDefaultArrayValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate),
                                )}
                                menuPosition="absolute"
                            />
                        )
                    } else {
                        return (
                            <SimpleSelect
                                id={attribute.technicalName ?? ''}
                                label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                                error={error?.message?.toString()}
                                info={attribute.description}
                                correct={isCorrect}
                                options={createOptions(constraints)}
                                disabled={attribute.readOnly || disabled}
                                control={control}
                                defaultValue={getDefaultValueForSimple(
                                    constraints,
                                    getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate),
                                )}
                                onChange={(val) => {
                                    getSpecialValueProperty({ technicalName: attribute.technicalName ?? '', entityName, value: val, setValue, watch })
                                }}
                                name={namePrefix + attribute.technicalName + nameSuffix}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                placeholder={t('createEntity.select')}
                                menuPosition="absolute"
                            />
                        )
                    }
                }
                return
            }

            case isRegex: {
                return (
                    <Input
                        correct={isCorrect}
                        info={t('validation.wrongRegex', { regexFormat: regexConstraints.regex })}
                        className={classnames(attClassNameConfig.attributes[attribute.technicalName]?.className || '')}
                        id={attribute.technicalName}
                        disabled={attribute.technicalName === AttributesConfigTechNames.METAIS_CODE || attribute.readOnly || disabled}
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        error={error?.message?.toString()}
                        {...register(namePrefix + attribute.technicalName + nameSuffix)}
                        type="text"
                        defaultValue={getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate)}
                        hint={hint}
                    />
                )
            }

            case hasNumericValue: {
                const isCurrency = attribute.units === EURO || attribute.units === EUR
                return (
                    <Controller
                        control={control}
                        name={namePrefix + attribute.technicalName + nameSuffix}
                        defaultValue={getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate)}
                        render={({ field: { onChange, value, ref } }) => (
                            <Input
                                className={classnames(attClassNameConfig.attributes[attribute.technicalName ?? '']?.className || '')}
                                name={namePrefix + attribute.technicalName + nameSuffix}
                                ref={ref}
                                value={isCurrency ? formatNumberWithSpaces(value) : value}
                                onChange={(e) => {
                                    const formattedValue = isCurrency
                                        ? e.target.value
                                              .replace(/,/g, '.')
                                              .replace(/[^(\d|.)]/g, '')
                                              .replace(/\s/g, '')
                                        : e.target.value
                                    onChange(formattedValue)
                                }}
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onKeyDown={(e: any) => {
                                    if (!isCurrency) {
                                        onKeyDownOfNumberInput(e)
                                    }
                                }}
                                type={isCurrency ? 'text' : 'number'}
                                hint={hint}
                                step={isDouble || isFloat ? 'any' : 1}
                                correct={isCorrect}
                                info={`${attribute.description} (${t('input.number.hint')})`}
                                id={attribute.technicalName}
                                disabled={attribute.readOnly || disabled}
                                label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel + unitsLabel}
                                error={error?.message?.toString()}
                                maxLength={maxLength}
                            />
                        )}
                    />
                )
            }

            case isHTML: {
                return (
                    <Controller
                        name={namePrefix + attribute.technicalName + nameSuffix}
                        control={control}
                        render={({ field: { name, onChange, value } }) => {
                            return (
                                <RichTextQuill
                                    id={attribute.technicalName ?? ''}
                                    label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                                    error={error?.message?.toString()}
                                    info={attribute.description}
                                    readOnly={attribute.readOnly || disabled}
                                    clearErrors={clearErrors}
                                    defaultValue={sanitizedHtml(getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate))}
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                />
                            )
                        }}
                    />
                )
            }

            case isTextarea: {
                return (
                    <TextArea
                        rows={3}
                        info={attribute.description}
                        correct={isCorrect}
                        id={attribute.technicalName}
                        disabled={attribute.readOnly || disabled}
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        error={error?.message?.toString()}
                        {...register(namePrefix + attribute.technicalName + nameSuffix)}
                        defaultValue={getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate)}
                        hint={hint}
                    />
                )
            }

            case hasStringValue: {
                return (
                    <Input
                        correct={isCorrect}
                        info={attribute.description}
                        className={classnames(attClassNameConfig.attributes[attribute.technicalName]?.className || '')}
                        id={attribute.technicalName}
                        disabled={attribute.technicalName === AttributesConfigTechNames.METAIS_CODE || attribute.readOnly || disabled}
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        error={error?.message?.toString()}
                        {...register(namePrefix + attribute.technicalName + nameSuffix)}
                        type={getStringType(attribute.attributeTypeEnum)}
                        defaultValue={getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate)}
                        hint={isEmail ? t('hint.emailFormat') : isPhone ? t('registration.phoneHint') : hint}
                        maxLength={maxLength}
                    />
                )
            }

            default: {
                // eslint-disable-next-line no-console
                console.warn('missing attribute input for: ' + attribute.technicalName)
                return (
                    <Input
                        correct={isCorrect}
                        info={attribute.description}
                        id={attribute.technicalName}
                        disabled={attribute.readOnly || disabled}
                        label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName}` + requiredLabel}
                        error={error?.message?.toString()}
                        {...register(namePrefix + attribute.technicalName + nameSuffix)}
                        type="text"
                        defaultValue={getDefaultValue(attribute.defaultValue ?? '', defaultValueFromCiItem, isUpdate)}
                        hint={hint}
                        maxLength={maxLength}
                    />
                )
            }
        }
    }
    return <>{renderContent()}</>
}
